//@ts-nocheck
import styled from 'styled-components';
import HomeHeading from './BlockHeading';
import Carousel from '../ui/Carousel';
import Container from '../Container';
import SeeMore from '../ui/SeeMore';
import theme from '../../style/theme';
import ExperienceItem from '../list/ExperienceItem';
import CampaignItem from '../list/CampaignItem';
import ActivityThemeItem from '../list/ActivityThemeItem';

interface DataProps {
  content_type?: string;
}
interface Props {
  data?: DataProps[];
}

const Wrapper = styled.section`
  overflow: hidden;
  background-color: ${theme.light_gy2};
  padding: 70px 0 25px;
  .slick-list {
    overflow: inherit;
  }
  .slick-dots {
    margin-left: -38px;
    margin-right: 38px;
  }
  @media ${theme.lg} {
    /* disable slider */
    border-top: 0;
    .slick-dots {
      display: none !important;
    }
    .slick-track,
    .slick-list {
      transform: none !important;
    }
    .slick-slider {
      width: auto !important;
    }
  }
`;
const SectionWrapper = styled.div`
  position: relative;
`;
const CarouselWrap = styled.div`
  margin: 0 -5px;
  @media ${theme.lg} {
    margin: 0 -7px;
  }
`;
const ItemWrap = styled.div`
  position: relative;
  padding: 0 5px;
  @media ${theme.sm} {
    padding: 0 7px;
  }
`;

const BlockBusinessRecentlyBooked = ({ data }: Props) => {
  // see more left position on mobile
  // (width of all element / 2) - width of see more
  if (!(data && data.length)) return <></>;
  return (
    <Wrapper className="on-screen-load">
      <HomeHeading title="Recently Booked" />
      <Container width={1162}>
        <SectionWrapper>
          <CarouselWrap>
            <Carousel variableWidth noArrows mint dynamicItemPerPage>
              {data.map((v, i) => {
                return (
                  <ItemWrap key={`item${i}`}>
                    {v.content_type === 'tour' ? (
                      <ExperienceItem data={v} fixed_width />
                    ) : v.content_type === 'campaign' ? (
                      <CampaignItem data={v} fixed_width />
                    ) : v.content_type === 'tour_theme' ? (
                      <ActivityThemeItem data={v} fixed_width />
                    ) : null}
                  </ItemWrap>
                );
              })}
            </Carousel>
          </CarouselWrap>
        </SectionWrapper>
      </Container>
    </Wrapper>
  );
};

export default BlockBusinessRecentlyBooked;
