import React from 'react';
import styled from 'styled-components';
import ElementDropdown from './BlockBusinessDropdown';
import theme from '/style/theme';
import { backgroundCover } from '/style/mixin';

const Wrapper = styled.div`
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/t5/main/slider/main_slide/20201207_main_slide_85_desktop.jpg');
  height: 500px;
  ${backgroundCover}
`;
const BlockDropdown = styled.div`
  position: relative;
  padding-top: 150px;
  text-align: center;
  @media ${theme.sm} {
    padding-top: 190px;
  }
`;
const Phrase = styled.div`
  font-size: 45px;
  margin-bottom: 5px;
  color: #fff;
  font-weight: ${theme.bold};
`;

const SubPhrase = styled.div`
  font-size: 18px;
  color: #fff;
  margin-bottom: 40px;
`;

export default () => {
  return (
    <Wrapper>
      <BlockDropdown>
        <Phrase>SAVE MONEY, TRAVEL CRAZY</Phrase>
        <SubPhrase>Book the latest things to do in Asia</SubPhrase>
        <ElementDropdown />
      </BlockDropdown>
    </Wrapper>
  );
};
