//@ts-nocheck
import React from 'react';
import { NextPageContext } from 'next';
import initialize from '../utils/initialize';
import getConfig from 'next/config';
import LayoutHome from '/components/Home/LayoutHome';
import LayoutHomeBusiness from '/components/Home/LayoutHomeBusiness';
import fetch from 'isomorphic-unfetch';
import { connect } from 'react-redux';

const { publicRuntimeConfig } = getConfig();

const Home = ({ subdomain, data, query }) => {
  return subdomain === 'business' ? (
    <LayoutHomeBusiness data={data} query={query} />
  ) : (
    <LayoutHome data={data} query={query} />
  );
};

Home.getInitialProps = async (ctx: NextPageContext) => {
  await initialize(ctx);
  const { authentication, subdomain } = ctx.store.getState();
  const { locale } = ctx;

  let params = {};
  let url_params = `?locale=${locale}`;
  if (subdomain === 'business') {
    url_params += '&business=1';
    if (authentication.token) {
      params = {
        method: 'GET',
        headers: {
          Authorization: 'bearer ' + authentication.token,
        },
      };
    }
  }
  const callData = await fetch(`${publicRuntimeConfig.API_PATH}/home${url_params}`, params);

  if (callData.status !== 200) {
    return {
      data: null,
    };
  }
  const data = await callData.json();
  return {
    data,
    query: ctx.query,
  };
};

export default connect(state => ({
  subdomain: state.subdomain,
}))(Home);
