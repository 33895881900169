import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import theme from '/style/theme';
import { backgroundCover } from 'style/mixin';
import { connect } from 'react-redux';
import actions from '/store/actions';
import bytesToSize from '/utils/bytesToSize';

const Wrapper = styled.div``;
const DropArea = styled.div`
  position: relative;
  margin: 4px 0 0 0;
  padding: 30px 10px;
  height: 100px;
  border-radius: 5px;
  border: dashed 1px ${theme.light_gy3};
  background-color: ${theme.light_gy1};
  text-align: center;
  color: ${theme.gy};
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.2px;
  outline: 0;
  cursor: pointer;
  input {
    outline: 0;
  }
  span {
    vertical-align: middle;
  }
  &.error {
    border: solid 1px ${theme.trazy_og};
    background-image: url(${require('/public/images/ui/ico-exclamation.svg')});
    background-repeat: no-repeat;
    background-position: 98.5% 6px;
  }
`;
const File = styled.div`
  position: relative;
  height: 50px;
  padding: 13px 40px;
  border: 1px solid ${theme.line_gy};
  border-radius: 5px;
  margin-top: 8px;
`;
const TextWrap = styled.div`
  display: table;
  width: 100%;
`;
const FileName = styled.div`
  display: table-cell;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.mid_gy};
`;
const Size = styled.div`
  text-align: right;
  display: table-cell;
  margin-left: 10px;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.light_gy4};
`;
const Checked = styled.div`
  position: absolute;
  top: 6px;
  left: 5px;
  width: 38px;
  height: 38px;
  background-image: url(${require('/public/images/ui/ico-check-og.svg')});
  ${backgroundCover}
`;
const Close = styled.div`
  position: absolute;
  top: 6px;
  right: 5px;
  cursor: pointer;
  width: 38px;
  height: 38px;
  text-indent: -9999px;
  background-image: url(${require('/public/images/ui/ico-delete.svg')});
  ${backgroundCover} /* position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  text-indent: -9999px;
  display: block;
  width: 38px;
  height: 38px;
  background-position: -76px 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_write_review.png'); */
`;

const FileList = styled.div`
  position: relative;
  margin-top: 8px;
  padding: 0;
`;
const SmallDropzone = ({ files, accept, setFiles, changeSweetAlert, removeFileByIndex, error }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept || 'image/*',
    maxSize: 10485760,
    onDrop: acceptedFiles => {
      const new_files = acceptedFiles
        .filter(v => {
          return !files.some(file => file.name === v.name);
        })
        .map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      const concat_files = [...files, ...new_files];
      if (concat_files.length > 10) {
        changeSweetAlert('Failed to upload files! You can upload up to 10 files');
        return;
      }
      setFiles(concat_files);
    },
    onDropRejected: res => {
      if (res && res.length) {
        const over_max_size = res.some(v => v.size > 10485760);
        if (over_max_size) changeSweetAlert('Oops! Please add a pic less than 10MB.');
      }
    },
  });
  return (
    <Wrapper>
      <DropArea {...getRootProps({ className: `dropzone ${error ? 'error': ''}` })}>
        <input {...getInputProps()} />
        <span
          dangerouslySetInnerHTML={{
            __html: require('../../public/images/ui/attachment.svg?include'),
          }}
        />
        Click or drag file here to upload (Up to 10 files and 10MB for each file)
      </DropArea>
      {files?.length ? (
        <FileList>
          {files.map((file, i) => {
            return (
              <File key={`thumbnail${i}`}>
                <Checked />
                <TextWrap>
                  <FileName>{file.name}</FileName>
                  <Size>{bytesToSize(file.size)}</Size>
                </TextWrap>
                <Close onClick={() => removeFileByIndex(i)}>close</Close>
              </File>
            );
          })}
        </FileList>
      ) : null}
    </Wrapper>
  );
};

export default connect(null, {
  changeSweetAlert: actions.changeSweetAlert,
})(SmallDropzone);
