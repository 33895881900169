// @ts-nocheck
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Chevron from '/components/ui/Chevron';
import { transition, resetList } from '/style/mixin';
import CityMenu from '../Header/CityMenu';
import Router from 'next/router';
import BgLayer from 'components/ui/BgLayer';

interface Props {
  location?: string;
  uistatus?: any;
}
const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 313px;
  margin: 0 auto;
  @media ${theme.sm} {
    width: 520px;
  }
`;
const Selected = styled.div`
  padding: 15px;
  height: 50px;
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: ${theme.mid_gy};
  background-color: #fff;
  cursor: pointer;
  .chevron-wrapper {
    position: absolute;
    top: 6px;
    right: 6px;
  }
`;
const Selectable = styled.div`
  max-height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  top: 50px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  ${transition()}
  ${props =>
    props.active &&
    `
    max-height: 600px;
  `}
`;
const SelectableInner = styled.div`
  padding: 5px 20px;
  > ul {
    ${resetList}
    > li {
      > span,
      > a {
        display: block;
        font-size: 16px;
        margin: 15px 0;
        line-height: 19px;
        font-weight: ${theme.bold};
        color: ${theme.brand};
      }
      > a {
        &:hover {
          text-decoration: underline;
        }
      }
      ul li {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 15px;
        a {
          font-size: 15px;
          color: ${theme.mid_gy};
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
const BlockBusinessDropdown = ({ location }: Props) => {
  const [dropdown, toggleDropdown] = useState(false);

  useEffect(() => {
    Router.events.on('routeChangeStart', () => toggleDropdown(false));
    return () => {
      Router.events.off('routeChangeStart', () => toggleDropdown(false));
    };
  }, []);

  return (
    <Wrapper>
      <Selected onClick={() => toggleDropdown(!dropdown)}>
        Where in Asia are you going?
        <Chevron className="chevron-wrapper" active={dropdown} />
      </Selected>
      {dropdown ? (
        <BgLayer
          z_index={1}
          transparent
          onClick={() => toggleDropdown(!dropdown)}
        />
      ) : null}
      <Selectable active={dropdown}>
        <SelectableInner>
          <CityMenu />
        </SelectableInner>
      </Selectable>
    </Wrapper>
  );
};
export default BlockBusinessDropdown;
